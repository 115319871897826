import React from "react"
import Header from "./header"
import Footer from "./footer"
import Helmet from "react-helmet"
import "../assets/snug.scss"
import "../assets/main.scss"
import BG from "../images/bg.jpg"
import ogImg from "../images/og.jpg"
import styles from "./layout.module.scss"

const Layout = ({ children }) => {
  return(
    <>
      <Helmet>
        <html lang="ja" />
        <title>MASATONAKAMURA.WORK</title>
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="description" content="Make good things 24/7." />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:url" content="https://masatonakamura.work/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="MASATONAKAMURA.WORK" />
        <meta property="og:description" content="Make good things 24/7." />
        <meta property="og:site_name" content="MASATONAKAMURA.WORK" />
        <meta property="og:image" content={ogImg} />
        <meta name="google-site-verification" content="iWyaTO-aAtO_dkkY6BWjbE5TuTFObf7gIMpnGIvGvrs" />
      </Helmet>
      <div style={{backgroundImage: `url(${BG})`}} className={styles.fillBg} />
      <main>
        {children}
      </main>
      <Footer />>
    </>
  )
}

export default Layout
