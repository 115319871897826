import React from "react"
import Link from "gatsby-link"
import styles from "./header.module.scss"
import Logo from "../images/logo.png"


class Header extends React.Component {
  state = { showMenu: false }

  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu
    })
  }

  render() {

    const menuActive = this.state.showMenu ? `${styles.stateOpen}` : '';
    const triggerActive = this.state.showMenu ?  `${styles.stateOpen}` : '';

    return (
      <>
        <header className={styles.header}>
          <div className={`${styles.headerInner} sng-container`}>
            <h1 className={styles.headerLogo}><img src={Logo} /></h1>
          </div>
          <div className={`${styles.trigger} ${triggerActive}`} onClick={this.toggleMenu}>
            <div className={`${styles.triggerLine} ${triggerActive}`}></div>
            <div className={`${styles.triggerLine} ${triggerActive}`}></div>
          </div>
          <nav className={`${styles.gNavWrapper} ${menuActive}`}>
            <ul className={styles.gNavList}>
              <li className={`${styles.gNavListIn} ${menuActive}`}>
                <Link to="/" className={styles.gNavListItem} onClick={this.toggleMenu}>HOME</Link>
              </li>
              <li className={`${styles.gNavListIn} ${menuActive}`}>
                <Link to="/" className={styles.gNavListItem} onClick={this.toggleMenu}>LOG</Link>
              </li>
              <li className={`${styles.gNavListIn} ${menuActive}`}>
                <Link to="/" className={styles.gNavListItem} onClick={this.toggleMenu}>BLOG</Link>
              </li>
              <li className={`${styles.gNavListIn} ${menuActive}`}>
                <Link to="/" className={styles.gNavListItem} onClick={this.toggleMenu}>CONTACT</Link>
              </li>
            </ul>
          </nav>
        </header>
      </>
    )

  }
}

export default Header
