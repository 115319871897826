import React from 'react'
import styles from './footer.module.scss'
const Footer = () => {
  return(
    <>
      <footer className={styles.wrapper}>
        &copy; MASATONAKAMURA.WORK
      </footer>
    </>
  )
}

export default Footer